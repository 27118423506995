

















































import { defineComponent } from "@vue/composition-api";
import { signIn } from "@/plugins/firebase";
import FormBase from "@/components/FormBase.vue";
import FormItem from "@/components/FormItem.vue";

type DataType = {
  email: string;
  password: string;
  error: string | null;
  isLoading: boolean;
};

export default defineComponent({
  name: "LoginPage",
  components: { FormBase, FormItem },
  data: (): DataType => ({
    email: "",
    password: "",
    error: null,
    isLoading: false,
  }),
  computed: {
    redirectQuery() {
      // @ts-ignore - Causes ts error during the build but works
      return this.$route.query.redirect || "/";
    },
  },
  methods: {
    async onSubmit() {
      this.error = null;
      this.isLoading = true;

      await signIn(this.email, this.password)
        .then(() => {
          this.$router.push(`${this.redirectQuery}`);
        })
        .catch(error => {
          if (error.message.includes("auth/too-many-requests")) {
            this.error =
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
          } else if (
            error.message.includes("auth/wrong-password") ||
            error.message.includes("auth/user-not-found")
          ) {
            this.error = "Invalid email or password";
          } else {
            this.error = error.message;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onAlertClose() {
      this.error = null;
    },
  },
});
